import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import AppMainRouter from './AppMainRouter';
import AppMainMobileRouter from './AppMainMobileRouter';
import Header from '../sharedComponents/Header/Header';
import { If, Then, Else } from 'react-if';
import { isMobile, isMobileOnly } from 'react-device-detect';
import HeaderLogo from '../sharedComponents/Header/HeaderLogo';
import HeaderLogoLoggedIn from '../sharedComponents/Header/HeaderLogoLoggedIn';
import LocalStorage from '../sharedComponents/LocalStorage/LocalStorage';
import ProfileSlideDrawer from '../sharedComponents/SlideDrawer/ProfileSlideDrawer';
import HelpSlideDrawer from '../sharedComponents/SlideDrawer/HelpSlideDrawer';
import NotificationsSlideDrawer from '../sharedComponents/SlideDrawer/NotificationsSlideDrawer';
import { AppShell } from '@mantine/core';
import ChangeAppsSlideDrawer from '../sharedComponents/SlideDrawer/ChangeAppsSlideDrawer';
import Logout from './Logout/Logout';

const AppMain = () => {
  const ready = true;
  const isLogin = useSelector(({user}) => user.isLogin);
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const [connectionMade, setConnectionMade] = useState(false);
  const [chatbotWidth, setChatbotWidth] = useState(isMobile ? viewportWidth : '600');
  const [chatbotHeight, setChatbotHeight] = useState(isMobile ? viewportHeight : '700');

  const mfrId = LocalStorage.manufacturerData.get().platform_id;
  const authToken = LocalStorage.authKey.get();
  const isAdmin = LocalStorage.userData.get().user.internalAdmin;

  const handleChatBotConnection = (event) => {
    if (event.data === 'chatbotHandshake') {
      setConnectionMade(true);
      event.source.postMessage('chatbotConnectionMade', event.origin);
    }
    if (event.data === 'chatbotReadyForData') {
      event.source.postMessage({authToken: authToken, platformId: mfrId, sessionId: authToken, internalUser: isAdmin, platform: 'manufacturer', forceMobileView: 'true', hideExpandIcon: isMobile}, event.origin);
    }
  }

  const handleChatbotChangeSize = (event) => {
    if (event.data === 'expandChatbotWindow') {
      setChatbotWidth(window.innerWidth * 0.82);
      setChatbotHeight(window.innerHeight);
    }
    if (event.data === 'shrinkChatbotWindow') {
      setChatbotWidth('600');
      setChatbotHeight('700');
    }
  }

  window.addEventListener('message', handleChatbotChangeSize);

  useEffect(() => {
    if (!connectionMade) {
      window.addEventListener("message", handleChatBotConnection);
      setConnectionMade(true);
      console.log("🚀 ~ Chatbot event listener attached");
    }
    return () => window.removeEventListener("message", handleChatBotConnection);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <If condition={isMobile}>
        <Then>
        <If condition={isLogin}>
          <Then>
            <HeaderLogoLoggedIn logo={process.env.REACT_APP_COMPANY_LOGO_COLOR} />
          </Then>
          <Else>
            <HeaderLogo logo={process.env.REACT_APP_COMPANY_LOGO} />
          </Else>
        </If>
        </Then>
        <Else>
          <ProfileSlideDrawer />
          <HelpSlideDrawer />
          <NotificationsSlideDrawer />
          <ChangeAppsSlideDrawer />
        </Else>
      </If>
      <If condition={ready && isLogin}>
        <Then>
          <If condition={isMobileOnly || isMobile }>
            <Then>
              <AppMainMobileRouter />
            </Then>
            <Else>
              <AppShell
                navbar={{
                  width: 250,
                  breakpoint: 'sm'
                }}
                padding="md"
              >
                <AppShell.Navbar>
                  <Header />
                </AppShell.Navbar>

                <AppShell.Main style={{height: '100vh'}}>
                  <AppMainRouter />
                </AppShell.Main>
              </AppShell>
            </Else>
          </If>
        </Then>
        <Else>
          <Logout />
        </Else>
      </If>
      <div id='chatbotContainer' style={{position: 'fixed', right: '0px', bottom: '-8px', zIndex: '5', visibility: 'hidden'}}>
        <iframe src={process.env.REACT_APP_CHATBOT_FRONTEND_URL} height={chatbotHeight} width={chatbotWidth} title="Chatbot" id='chatbotIframe' style={{background: 'white', boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)', border: 'white solid 4px', transition: 'height 0.75s, width 0.75s'}}></iframe>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLossy
        draggable
        pauseOnHover
      />
    </Fragment>
  );
};

export default AppMain;