import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import LocalStorage from '../../../sharedComponents/LocalStorage/LocalStorage';
import { useDispatch } from 'react-redux';
import { setAdmin } from '../../../reducers/UserReducer/UserActions';
import { useNavigate } from 'react-router-dom';
import { clearDataSuite, clearDataSuiteHighlights } from '../../../reducers/DatasuiteReducer/DataSuiteActions';
import { clearCompetitiveInsightsData } from '../../../reducers/CompetitiveInsightsReducer/CompetitiveInsightsActions';
import { clearOpportunititesData } from '../../../reducers/OpportunitiesReducer/OpportunitiesActions';
import { clearMarketingCampaignsData } from '../../../reducers/MarketingCampaignsReducer/MarketingCampaignsActions';
import { slideDrawer } from '../../../reducers/UIReducer/UIActions';
import { Theme } from '../../../config/constants';
import { clearDashboardData } from '../../../reducers/DashboardReducer/DashboardActions';

const Profile = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAdmin = LocalStorage.userData.get().user.internalAdmin === true;
    var mfrName = LocalStorage.manufacturerData.get().nickname;
    var mfrLetters = '';

    const goToChangeManufacturer = () => {
        dispatch(slideDrawer.subtract());
        dispatch(slideDrawer.subtractDatasuiteDetails());
        dispatch(slideDrawer.subtractProfile());
        dispatch(slideDrawer.subtractComp());
        dispatch(slideDrawer.subtractHelp());
        dispatch(slideDrawer.subtractMfr());
        dispatch(slideDrawer.subtractOpp());
        dispatch(clearCompetitiveInsightsData());
        dispatch(clearOpportunititesData());
        dispatch(clearMarketingCampaignsData());
        dispatch(clearDataSuiteHighlights());
        dispatch(clearDataSuite());
        dispatch(clearDashboardData());
        dispatch(setAdmin(true));
        localStorage.removeItem('manufacturerData');
        navigate('/admin-login');
      };

    mfrLetters = mfrName.split(' ').map(word => word.charAt(0)).join('');

    return (
        <Container fluid className={(isMobile ? '':'px-2') + ' mb-4'}>
            <Row className='mt-4 mb-1'>
                <Col className='col-12'>
                    <Row className='mb-2'>
                        <div className='mx-auto' style={{height: (isMobile ? '75px' : '100px'), width: (isMobile ? '75px' : '100px'), borderRadius: '50%', background: 'lightgray'}}>
                            <div className={(isMobile ? "text-size-20 w-fc mx-auto" : "text-size-24 w-fc mx-auto")}  style={{position: 'relative', top: '33%'}}>{mfrLetters[0]}{mfrLetters[1]}</div>
                        </div>
                    </Row>
                    <Row>
                        <div className={(isMobile ? "text-size-20 w-fc mx-auto" : "text-size-24 w-fc mx-auto")}>{mfrName}</div>
                    </Row>
                </Col>
            </Row>
            {isAdmin && 
            <Row>
                <Col className='col-12'>
                    <Row className='mb-4'>
                        <div className='mx-auto w-fc px-3 py-1' style={{background: '#49A4DA', color: 'white', borderRadius: '4px'}}>ADMIN</div>
                    </Row>
                    <Row>
                        <Button color={Theme().colors.accentName} outline={true} block className='py-2 px-5 w-fc mx-auto button-label' onClick={goToChangeManufacturer}>Change Manufacturer</Button>
                    </Row>
                </Col>
            </Row> 
            }
            <Row className='mt-4'>
                <div style={{width: '100%', height: '1px', background: 'lightgray'}}></div>
            </Row>
        </Container>
        
    )
}

export default Profile;
