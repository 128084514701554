import { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import useLogin from "../../sharedComponents/useLogin/useLogin";
import { setAdmin, setFullManufacturersListData } from '../../reducers/UserReducer/UserActions';
import { useNavigate } from "react-router-dom";
import userApi from "../../api/userApi";
import LocalStorage from "../../sharedComponents/LocalStorage/LocalStorage";
import AlertModal from "../../sharedComponents/AlertModal/AlertModal";
import { showModal } from "../../reducers/ModalsReducer/ModalsActions";

const reducer = (state, action) => {
  switch(action.type) {
    case 'SET_MANUFACTURER_LIST':
      return {
        ...state,
        manufacturers: action.payload.map(u => {
          return {
            label: `${u.nickname} (${u.platform_id})`,
            value: u
          };
        })
      }
    case 'SET_USER_LIST':
      return {
        ...state,
        users: action.payload.map(u => {
          return {
            label: `${u.first_name} ${u.last_name} (${u.email})`,
            value: {
              ...u,
              isAdmin: false
            }
          };
        })
      }
    case 'SELECT_OPTION':
      return {
        ...state,
        selectedManufacturer: action.payload
      }
    case 'SELECT_USER_OPTION':
      return {
        ...state,
        selectedUser: action.payload,
        dashboard: action.payload?.value?.dashboard,
        chainData: action.payload?.value?.chainData,
        gpoData: action.payload?.value?.emergingData
      }
    case 'TOGGLE_DASH_ACCESS':
      return {
        ...state,
        dashboard: !state.dashboard
      }
    case 'TOGGLE_CHAIN_DATA':
      return {
        ...state,
        chainData: !state.chainData
      }
    case 'TOGGLE_GPO_DATA':
      return {
        ...state,
        gpoData: !state.gpoData
      }
    default:
      return state;
  }
};

const useAdminLogin = () => {
  const dispatch = useDispatch();
  const fullManufacturersList = useSelector(({user}) => user.fullManufacturersList);
  const [{manufacturers, selectedManufacturer, users, selectedUser, chainData, gpoData, dashboard}, set] = useReducer(reducer, {manufacturers: [], selectedManufacturer: null, users: [], selectedUser: null, chainData: false, gpoData: false, dashboard: false});
  const navigate = useNavigate();
  const { manufacturerLogin, adminUserLogin } = useLogin();

  useEffect(() => {
    if(fullManufacturersList.length <= 0) {
      dispatch(setFullManufacturersListData(LocalStorage.allManufacturerData.get()));
      set({type: 'SET_MANUFACTURER_LIST', payload: LocalStorage.allManufacturerData.get()});
    } else {
      set({type: 'SET_MANUFACTURER_LIST', payload: fullManufacturersList});
    }
    // eslint-disable-next-line
  }, []);

  const setUserList = (userList) => {
    set({type: 'SET_USER_LIST', payload: userList});
  };

  const onChange = (opt) => {
    if(opt.value.email) {
      set({type: 'SELECT_USER_OPTION', payload: opt});
    }
    else {
      set({type: 'SELECT_OPTION', payload: opt});
      set({type: 'SELECT_USER_OPTION', payload: null});
      const params = {
        'manufacturer' : opt.value
      };
      userApi.post(params).then(({data}) => {
        setUserList(data);
      }).catch(err => {
        console.error(err);
        toast.error('Something went wrong saving the user');
      });
    }
  };

  const onLogin = (e) => {
    e.preventDefault();
    if(LocalStorage.userData.get() === null) {
      navigate('/logout');
      return;
    }
    if(selectedManufacturer && selectedUser) {
      adminUserLogin(selectedUser.value.id);
    }
    else {
      manufacturerLogin(selectedManufacturer.value);
      dispatch(setAdmin(false));
      navigate('/');
    }
  };

  const onLogout = () => {
    navigate('/logout');
  };

  const onAdminEdit = (e) => {
    e.preventDefault();

    const params = {
      userId: selectedUser.value.id,
      chainData: chainData,
      emergingData: gpoData,
      dashboard: dashboard
    };

    userApi.permissions.post(params).then(() => {
      dispatch(showModal(AlertModal, {title: "User updated successfully!"}));
    }).catch(() => {
      dispatch(showModal(AlertModal, {title: "There was a problem updating this user."}));
    });
  };

  const setChainData = () => {
    set({type: 'TOGGLE_CHAIN_DATA'});
  };

  const setGpoData = (e) => {
    set({type: 'TOGGLE_GPO_DATA'});
  };

  const setDashAccess = (e) => {
    set({type: 'TOGGLE_DASH_ACCESS'});
  };

  return {
    mfrSelectProps: {
      onChange,
      value: selectedManufacturer,
      options: manufacturers
    },
    userSelectProps: {
      onChange,
      value: selectedUser,
      options: users
    },
    chainData,
    gpoData,
    dashboard,
    onLogin,
    onLogout,
    onAdminEdit,
    setUserList, 
    setDashAccess,
    setChainData,
    setGpoData
  };
};

export default useAdminLogin;